import axios from "axios";
import { PlayIcon } from "components/IconsOnly";
import ViewImageModal from "components/ViewImageModal";
import "css/Images.css";
import "css/OrderByIdPage.css";
import { formatDateAndTime } from "helpers/date";
import { videoFormats } from "models/mediaFormats";
import { FetchedOrderImages, OrderById } from "models/responseModels";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Col, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";
import { clearOrderByIdData, setImageModalDescription, setOrderByIdData } from "redux/Order/action";

export type ImageModalType = "VIEW" | "ADD" | "";

function OrderHistoryImagePage() {
  const [fetchedImages, setFetchedImages] = useState<FetchedOrderImages[]>();
  const [preview, setPreview] = useState<string | undefined>();
  const [file, setFile] = useState<File | null>();
  const [alteredFile, setAlteredFile] = useState<File>();
  const [imageModal, setImageModal] = useState<{
    type: ImageModalType;
    fileName?: string;
    mediaId?: number;
  }>({
    type: "",
    fileName: "",
    mediaId: 0,
  });
  const orderId = useParams<{ id: string }>().id;
  const dispatch = useDispatch();

  const fetchOrderById = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      const result = res.data;
      dispatch(setOrderByIdData(result));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const fetchAllMediaByOrderId = async () => {
    dispatch(setIsLoading(true));
    try {
      const res = await axios.get(`/order-media/${orderId}`);
      const result = res.data;
      setFetchedImages(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleExit = () => {
    setImageModal({ type: "" });
    dispatch(setImageModalDescription(""));
    setFile(null);
  };

  useEffect(() => {
    fetchAllMediaByOrderId();
  }, []);

  useEffect(() => {
    fetchOrderById();
    return () => {
      dispatch(clearOrderByIdData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      setImageModal({ type: "" });
    } else {
      const fileName = file.name.toLowerCase();
      let alteredFile: File | undefined;
      if ([".quicktime", ".mov"].some((i) => fileName.endsWith(i))) {
        const blob = file.slice(0, file.size, "video/mp4");
        const arr = file.name.split(".");
        alteredFile = new File(
          [blob],
          file.name.slice(0, file.name.length - arr[arr.length - 1].length) + "mp4",
          {
            type: "video/mp4",
          }
        );
        setAlteredFile(alteredFile);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(alteredFile ?? file);
      setImageModal({ type: "ADD", fileName: alteredFile ? alteredFile.name : file.name });
    }
  }, [file]);

  return (
    <div
      className="m-0 full-width flex-column-start relative"
      style={{
        width: "100vw",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <div
        className="flex-column-center"
        style={{
          width: "100vw",
          overflowY: "auto",
        }}
      >
        <Row
          className="px-3"
          style={{
            width: "100vw",
            minHeight: "64px",
            display: "grid",
            gridTemplateColumns: fetchedImages && !fetchedImages.length ? "1fr" : "1fr 1fr",
          }}
        >
          {fetchedImages && !fetchedImages.length ? (
            <Col className="my-4">{"暫無圖片記錄"}</Col>
          ) : (
            fetchedImages?.map((item) => (
              <Col key={item.id} className="px-1 py-3 flex-column-start full-width imageContainer">
                {videoFormats.includes(item.fileName.split(".")[1]) ? (
                  <>
                    <div
                      className="full-size flex-column-center"
                      onClick={() => {
                        setImageModal({
                          type: "VIEW",
                          fileName: item.fileName,
                          mediaId: item.id,
                        });
                        dispatch(setImageModalDescription(item.description));
                      }}
                    >
                      <PlayIcon />
                      {item.description && (
                        <div
                          className="my-1 flex-column-start"
                          style={{ maxHeight: "60%", overflowY: "scroll" }}
                        >
                          {item.description}
                        </div>
                      )}
                    </div>
                    <div className="flex-center dateContainer">
                      {formatDateAndTime(item.createdAt)}
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      alt={item.fileName}
                      className="image"
                      src={`https://wingold-s3.s3.ap-east-1.amazonaws.com/${item.fileName}`}
                      onClick={() => {
                        setImageModal({
                          type: "VIEW",
                          fileName: item.fileName,
                          mediaId: item.id,
                        });
                        dispatch(setImageModalDescription(item.description));
                      }}
                    />
                    <div
                      className="flex-column-start py-1"
                      style={{
                        height: "fit-content",
                        width: "100%",
                        maxHeight: "48px",
                        overflowY: "auto",
                      }}
                    >
                      {item.description}
                    </div>
                    <div className="flex-center dateContainer">
                      {formatDateAndTime(item.createdAt)}
                    </div>
                  </>
                )}
              </Col>
            ))
          )}
          <div style={{ height: "280px" }} />
        </Row>
      </div>
      <ViewImageModal
        imageModal={imageModal}
        image={preview}
        handleExit={handleExit}
        onSubmit={() => {}}
        submitEdit={() => {}}
        readOnly
      />
    </div>
  );
}

export default OrderHistoryImagePage;
