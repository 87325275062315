import "css/Images.css";
import { videoFormats } from "models/mediaFormats";
import { ImageModalType } from "pages/ImageUploadPage";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { setImageModalDescription } from "redux/Order/action";
import { IRootState } from "store";
import TextareaAutosize from "react-autosize-textarea/lib";

interface ViewImageModalProps {
  imageModal: { type: ImageModalType; fileName?: string | undefined; mediaId?: number };
  image?: string;
  handleExit: () => void;
  onSubmit: () => void;
  submitEdit?: (args0: number) => void;
  handleDeleteMedia?: (args0: number) => void;
  readOnly?: boolean;
}

export default function ViewImageModal(props: ViewImageModalProps) {
  const { imageModal, image, handleExit, onSubmit, submitEdit, readOnly = false } = props;
  const [originalDescription, setOriginalDescription] = useState("");
  const testRef = useRef<HTMLTextAreaElement>(null);
  const reduxImage = useSelector((state: IRootState) => state.orderById.image);
  const orderData = useSelector((state: IRootState) => state.orderById.orderById);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const orderCompleteDate = !!orderData.completeDate ? new Date(orderData.completeDate) : undefined;
  const is7DaysAfter =
    !!orderCompleteDate &&
    Date.now().valueOf() > orderCompleteDate.setDate(orderCompleteDate.getDate() + 7).valueOf();

  const dispatch = useDispatch();

  useEffect(() => {
    setOriginalDescription(reduxImage.imageDescription);
  }, [imageModal]);

  const MAX_HEIGHT = "45vh";
  const fileNameSplit = imageModal.fileName ? imageModal.fileName.split(".") : [];
  const fileExtension = fileNameSplit[fileNameSplit.length - 1];
  const allowToEdit = (!is7DaysAfter && userRole === "WORKER") || userRole !== "WORKER";

  return (
    <Modal click size="xl" isOpen={!!imageModal.type} centered contentClassName="imageModal">
      <Container size="lg" className="p-3" style={{ position: "relative" }}>
        {/* {imageModal.type === "ADD" && (
          <Row className="flex-center">
            <h5 className="flex-center">Image Preview</h5>
          </Row>
        )} */}
        <Row className="flex-center">
          {imageModal.fileName && videoFormats.includes(fileExtension) ? (
            <video
              style={{ maxHeight: MAX_HEIGHT }}
              controls
              src={
                imageModal.type === "ADD"
                  ? image
                  : imageModal.type === "VIEW"
                  ? `https://wingold-s3.s3.ap-east-1.amazonaws.com/${imageModal.fileName}`
                  : ""
              }
            />
          ) : (
            <img
              alt={imageModal.fileName}
              className="image"
              style={{ width: "100%", objectFit: "scale-down", maxHeight: MAX_HEIGHT }}
              src={
                imageModal.type === "ADD"
                  ? image
                  : imageModal.type === "VIEW"
                  ? `https://wingold-s3.s3.ap-east-1.amazonaws.com/${imageModal.fileName}`
                  : ""
              }
            />
          )}
        </Row>
        <Row className="p-3 flex-center">
          <div className="mx-1">圖片描述:</div>
          <TextareaAutosize
            readOnly={!allowToEdit || readOnly}
            rows={4}
            ref={testRef}
            className="mx-1"
            value={reduxImage.imageDescription}
            onChange={(e) => {
              if (allowToEdit && !readOnly) {
                dispatch(setImageModalDescription(e.currentTarget.value));
              }
            }}
          />
        </Row>

        {imageModal.mediaId && imageModal.type === "VIEW" && (
          <>
            <Row className="my-4 flex-center">
              <Col className="flex-center w-50">
                <Button style={{ width: "100%" }} onClick={handleExit}>
                  {"取消"}
                </Button>
              </Col>
              {allowToEdit && !readOnly && (
                <Col className="flex-center w-50">
                  <Button
                    style={{ width: "100%" }}
                    color="primary"
                    disabled={originalDescription === reduxImage.imageDescription}
                    onClick={submitEdit ? () => submitEdit(imageModal.mediaId!) : () => {}}
                  >
                    更改儲存
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
        {!readOnly && allowToEdit && imageModal.type === "ADD" && (
          <>
            <Row className="my-4 flex-row-between">
              <Col className="flex-center w-50">
                <Button style={{ width: "100%" }} onClick={handleExit}>
                  {"返回"}
                </Button>
              </Col>
              <Col className="flex-center w-50">
                <Button style={{ width: "100%" }} color="primary" onClick={onSubmit}>
                  確認上傳
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Modal>
  );
}
