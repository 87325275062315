import React from "react";

const JobCompletionSignatureFields = () => {
  return (
    <section className="full-width" style={{ pageBreakInside: "avoid" }}>
      <div className="flex-row-start">
        <h6>{"WIN GOLD ENGINEERING & SURVEYING LTD"}</h6>
      </div>
      <div className="flex-row-start align-items-start" style={{ marginTop: "80px" }}>
        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Company Representative"}
          <br />
          {"WIN GOLD 代表"}
        </div>
        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Client's Representative"}
          <br />
          {"客戶代表"}
        </div>

        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Contact Telephone"}
          <br />
          {"客戶聯絡電話"}
        </div>

        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Date"}
          <br />
          {"日期"}
        </div>
      </div>
    </section>
  );
};
export default JobCompletionSignatureFields;
