import axios from "axios";
import { AssignList } from "components/AssignList";
import DeleteModal from "components/DeleteModal";
import Documents from "components/Documents";
import { CallIcon, CloseIcon, DeleteIcon, SearchIcon, WhatsappIcon } from "components/IconsOnly";
import NewDocumentModal from "components/NewDocumentModal";
import { DateTimeSectionRow } from "components/OrderInfo/DateTimeSectionRow";
import {
  DynamicsSectionRow,
  ProgressStatusDropDown,
  SectionReport,
  SectionRow,
} from "components/OrderInfo/OrderSection";
import PreviewNewDocumentModal from "components/PreviewNewDocument";
import ReactstrapTabNavLink from "components/ReactstrapTabNavLink";
import "css/OrderByIdPage.css";
import "css/Table.css";
import { formatDate, formatDateAndTime } from "helpers/date";
import { A_ADD_CLIENT, A_ASSIGN_WORKER, midLevelUp } from "models/authorities";
import { FetchAssignedWorkers, OrderById } from "models/responseModels";
import {
  AreaType,
  DIVISIONS,
  DivisionType,
  PROGRESS_STATUS,
  SERVICE_TYPES,
} from "models/variables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Nav, Row, Spinner, TabContent } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setChangesMade, setIsLoading, setMessageModal } from "redux/Loading/action";
import {
  assignWorkerToList,
  clearOrderByIdData,
  resetWorkerList,
  setOrderByIdData,
  setServiceReport,
} from "redux/Order/action";
import { setScreenIndicatorText } from "redux/ScreenIndicator/action";
import { IRootState } from "store";

export interface AllWorkersType {
  id: number;
  chiName: string;
  mobile: string;
  staffNumber: string;
  role: string;
  division: DivisionType;
  residenceDistrict: string;
  area: AreaType;
}

function OrderByIdPage() {
  const [activeTab, setActiveTab] = useState("WORKER");
  const [modal, setModal] = useState(false);
  const [assignUserOpen, setAssignUserOpen] = useState(false);
  const [editTarget, setEditTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [modalSearchText, setModalSearchText] = useState("");
  const [originalAssignedWorkers, setOriginalAssignedWorkers] = useState<number[]>([]);
  const [allUsers, setAllUsers] = useState<AllWorkersType[]>([]);
  const [openDynamics, setOpenDynamics] = useState(false);
  const [workerLists, setWorkerLists] = useState<{
    supervisorList: AllWorkersType[];
    foremenList: AllWorkersType[];
    workerList: AllWorkersType[];
  }>({ supervisorList: [], foremenList: [], workerList: [] });
  const [assignedWorkers, setAssignedWorkers] = useState<FetchAssignedWorkers[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditReportMode, setIsEditReportMode] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const auth = useSelector((state: IRootState) => state.auth);
  const userRole = auth.role;
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);
  const pathnameSplit = pathname.split("/");
  const orderId = pathnameSplit[pathnameSplit.length - 1];
  const dispatch = useDispatch();
  const changesMade = useSelector((state: IRootState) => state.loading.changesMade);
  const orderById = useSelector((state: IRootState) => state.orderById);
  const order = orderById.orderById;
  const global = useSelector((state: IRootState) => state.loading);
  const isLoading = global.isLoading;
  const orderNumber = orderById.orderById.orderNumber;
  const assignWorkers = orderById.assignWorkers;
  const tabs = ["WORKER", "FOREMEN", "SUPERVISOR"];

  function toggle(tab: string) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const putOrderData = async (key: keyof OrderById) => {
    try {
      await axios.put(`/orders/${orderId}`, {
        order: {
          ...order,
          [key]: order[key],
        },
      });
      dispatch(setChangesMade(true));
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleSubmitReport = async () => {
    try {
      await axios.put(`/orders/${orderId}`, {
        order: {
          ...order,
          serviceReport: order.serviceReport,
        },
      });
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const handleSubmitSubject = async () => {
    try {
      await axios.put(`/orders/${orderId}`, {
        order: {
          ...order,
          serviceSubject: order.serviceSubject,
        },
      });
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const postAssignWorkersToOrder = async () => {
    const newArr = assignWorkers.filter((i) => !originalAssignedWorkers.includes(i));
    try {
      await axios.post(`/order-staffs/${orderId}`, {
        staffIds: newArr,
      });
      dispatch(setChangesMade(true));
      dispatch(resetWorkerList());
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      setAssignUserOpen(false);
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
      setAssignUserOpen(false);
    }
  };

  const fetchOrderById = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      const result = res.data;
      const res2 = await axios.get(`order-survey/${orderId}`);
      const result2 = await res2.data;
      setIsNew(!result2.data);
      dispatch(setOrderByIdData({ ...result, surveyCompleted: !!result2.data }));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const fetchAllUsers = async (isDesc?: boolean, orderBy?: string) => {
    // AssignWorkers
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/users`);
      url.searchParams.set("type", "form");
      url.searchParams.set("orderId", `${orderId}`);
      if (orderBy) {
        url.searchParams.set("order", `${orderBy}`);
      }
      if (isDesc !== undefined) {
        url.searchParams.set("direction", `${isDesc ? "desc" : "asc"}`);
      }
      if (!!modalSearchText) url.searchParams.set("search", modalSearchText);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAllUsers(result.data);
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const fetchAssignedWorkers = async () => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/order-staffs/${orderId}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAssignedWorkers(result.data);
      for (const i of result.data) {
        dispatch(assignWorkerToList(i.id));
      }
      setOriginalAssignedWorkers(result.data.map((i: any) => i.id));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setIsLoading(false));
  };

  const windowSize = "height=600,width=400,left=100,top=100";

  const loadMessage = (phoneNumber: string) => {
    const order = orderById.orderById;
    const message = encodeURIComponent(
      `*新工作單安排${orderNumber ?? ""}*
      \n客戶：${order.clientName}\n地址：${order.clientAddress ?? "-"}
      \n預計開始日期：${order.expectedStartDate ? formatDate(order.expectedStartDate) : "-"}
      \n預計完工日期：${order.expectedCompleteDate ? formatDate(order.expectedCompleteDate) : "-"}
      \n服務性質：${order.serviceType ? SERVICE_TYPES[order.serviceType] : "-"}
      \n服務事項：\n${order.serviceSubject ?? "-"}
      \n\n請按以下連結檢視工作單：\nhttps://mobile.wingold.hk/order/${order.id}
      \n\n如有其他查詢請與公司聯絡。`
    );
    window.open(`https://wa.me/852${phoneNumber}?text=${message}`, "ViewCoMessage", windowSize);
  };

  //useEffect
  useEffect(() => {
    dispatch(setIsLoading(true));
    try {
      fetchOrderById();
      fetchAssignedWorkers();
      if (midLevelUp.includes(auth.role)) {
        fetchAllUsers();
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsLoading(false));
    }
    return () => {
      dispatch(clearOrderByIdData());
    };
  }, [dispatch, auth.role]);

  useEffect(() => {
    if (!order.orderNumber) return;
    dispatch(setScreenIndicatorText(order.orderNumber));
  }, [dispatch, order.orderNumber]);

  useEffect(() => {
    setWorkerLists({
      supervisorList: allUsers.filter((i) => i.role === "SUPERVISOR"),
      foremenList: allUsers.filter((i) => i.role === "FOREMEN"),
      workerList: allUsers.filter((i) => i.role === "WORKER"),
    });
  }, [allUsers, assignedWorkers, assignWorkers, assignUserOpen, changesMade]);

  useEffect(() => {
    if (changesMade) {
      fetchAllUsers();
      fetchAssignedWorkers();
      dispatch(setChangesMade(false));
    }
  }, [dispatch, changesMade]);

  return (
    <Container
      className="m-0 px-0 full-width flex-column-start relative"
      style={{
        maxHeight: "calc(100vh - 108px)",
        paddingBottom: "10em",
        width: "100vw",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {isLoading ? (
        <Row className="my-4 flex-center">
          <Col className="flex-center">
            <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
            <h4 className="mx-4">Loading</h4>
          </Col>
        </Row>
      ) : (
        <Row
          className="flex-center w-95"
          style={{ position: "relative", minHeight: "max-content" }}
        >
          {midLevelUp.includes(userRole) && (
            <div className="mt-2 full-width flex-center">
              <Button onClick={() => setOpenDynamics(true)}>查看發單資料</Button>
              <Modal isOpen={openDynamics}>
                <Container className="pb-4">
                  <div className="pt-2 flex-row-end">
                    <div className="p-2" onClick={() => setOpenDynamics(false)}>
                      <CloseIcon />
                    </div>
                  </div>
                  <DynamicsSectionRow
                    keyText={"Bill to client"}
                    valueText={order.billToClient ?? ""}
                  />
                  <DynamicsSectionRow
                    keyText={"Person in charge"}
                    valueText={order.personInCharge ?? ""}
                  />
                  <DynamicsSectionRow
                    keyText={"Customer Code"}
                    valueText={order.customerCode ?? ""}
                  />
                  <DynamicsSectionRow
                    keyText={"Quotation No."}
                    valueText={order.salesQuoteNo ?? ""}
                  />
                  <DynamicsSectionRow
                    keyText={"Customer PO No."}
                    valueText={order.externalDocumentNo ?? ""}
                  />
                  <DynamicsSectionRow
                    keyText={"Contract Amount"}
                    valueText={order.contractAmount?.toString() ?? ""}
                  />
                  <h6 className="mt-3 mb-0 full-width flex-center" style={{ color: "#AAA" }}>
                    *如要更改任何資料請通知admin同事*
                  </h6>
                </Container>
              </Modal>
            </div>
          )}
          <div className="py-2 my-2 sectionContainer">
            <SectionRow keyText={"工作單號"} valueText={order.orderNumber || "-"} />
            <SectionRow
              keyText={"發單日期"}
              valueText={order.createdAt ? formatDateAndTime(order.createdAt) : "-"}
            />
            <SectionRow keyText={"客戶名稱"} valueText={order.clientName || "-"} />
            <SectionRow keyText={"工作地點"} valueText={order.clientAddress || "-"} />
            <SectionRow
              keyText={"預計開始日期"}
              valueText={order.expectedStartDate ? formatDate(order.expectedStartDate) : "-"}
            />
            <SectionRow
              keyText={"預計完工日期"}
              valueText={order.expectedCompleteDate ? formatDate(order.expectedCompleteDate) : "-"}
            />
            <SectionRow keyText={"聯絡人"} valueText={order.clientContactPerson || "-"} />
            {userRole !== "WORKER" && (
              <SectionRow keyText={"聯絡電話"} valueText={order.clientTel || "-"} />
            )}
            <SectionRow keyText={"服務性質"} valueText={SERVICE_TYPES[order.serviceType] ?? "-"} />
          </div>
          <div className="py-2 my-2 sectionContainer">
            <DateTimeSectionRow
              keyText={"施工日期"}
              valueText={order.startDate ?? ""}
              index={"startDate"}
              putFunction={() => putOrderData("startDate")}
            />
            <DateTimeSectionRow
              keyText={"完工日期"}
              valueText={order.completeDate ?? ""}
              index={"completeDate"}
              putFunction={() => putOrderData("completeDate")}
            />
            <SectionRow
              keyText={"ACJ 號碼 / P.O. 號碼"}
              valueText={order.acjNo ? order.acjNo : "-"}
              editable
              index={"acjNo"}
              putFunction={() => putOrderData("acjNo")}
            />
            {midLevelUp.includes(userRole) ? (
              <ProgressStatusDropDown />
            ) : (
              <SectionRow
                keyText={"進度"}
                valueText={PROGRESS_STATUS[order.progressStatus] ?? ""}
              />
            )}
          </div>
          <div className="py-2 my-2 sectionContainer">
            <SectionRow keyText={"消息來源"} valueText={order.informedBy || "-"} />
            <SectionRow keyText={"工程部門"} valueText={DIVISIONS[order.division]} />
          </div>
          <div className="py-2 my-2 sectionContainer flex-center">
            <SectionReport
              keyText={"服務事項"}
              valueText={order.serviceSubject || "-"}
              editText={setServiceReport}
              isEditMode={isEditMode}
              activateEdit={setIsEditMode}
              idx={"serviceSubject"}
              onSubmit={handleSubmitSubject}
            />
          </div>
          <div className="py-2 my-2 sectionContainer flex-center">
            <SectionReport
              keyText={"服務報告"}
              valueText={order.serviceReport || "-"}
              editText={setServiceReport}
              isEditMode={isEditReportMode}
              activateEdit={setIsEditReportMode}
              idx={"serviceReport"}
              onSubmit={handleSubmitReport}
            />
          </div>
          <section className="pt-2 my-2 sectionContainer flex-center">
            <div
              className="my-2 px-2 flex-column-start full-width"
              style={{ alignItems: "flex-start", position: "relative" }}
            >
              <div className="px-2 mx-2 sectionHead flex-row-between">
                <div className="mb-2">{"參與員工"}</div>
                {A_ASSIGN_WORKER.includes(userRole) && (
                  <Button
                    color="primary"
                    className="mb-2 px-2 py-1 flex-center full-height"
                    style={{ borderRadius: "4px", border: "none" }}
                    onClick={() => {
                      setAssignUserOpen(true);
                    }}
                  >
                    管理相關人員
                  </Button>
                )}
              </div>
              <div className="pb-2 full-width flex-column-start" style={{ overflowX: "auto" }}>
                {!assignedWorkers.length ? (
                  <div className="flex-center pt-2">尚未安排</div>
                ) : (
                  assignedWorkers.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="m-1 full-width flex-row-start"
                        style={{ minHeight: "32px" }}
                      >
                        {isNew && A_ASSIGN_WORKER.includes(userRole) && (
                          <div className="mx-1 flex-row-around actionContainer pointer">
                            <div
                              className="flex-center tdIconContainer full-height"
                              onClick={() => loadMessage(item.mobile)}
                            >
                              <WhatsappIcon />
                            </div>
                            <div
                              className="flex-center tdIconContainer full-height"
                              onClick={() => {
                                setModal(!modal);
                                setEditTarget({ name: item.chiName, id: item.id });
                              }}
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        )}
                        <div className="mx-2 flex-row-start staffNumberContainer">
                          {item.staffNumber || "-"}
                        </div>
                        <div className="mx-2 flex-row-start nameContainer">
                          {item.chiName || "-"}
                        </div>
                        <div className="mx-2 flex-row-start callAndMobile">
                          <CallIcon />
                          <a
                            className="mx-2"
                            href={`tel:(852)${item.mobile}`}
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            {item.mobile}
                          </a>
                        </div>
                        <div className="mx-2 flex-row-start roleContainer">{item.role}</div>
                        <div className="mx-2 flex-row-start roleContainer">
                          {"加入時間：" + formatDateAndTime(item.createdAt)}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </section>

          <NewDocumentModal />
          <Documents />
          <PreviewNewDocumentModal />
        </Row>
      )}
      {A_ASSIGN_WORKER.includes(userRole) && (
        <>
          <Modal isOpen={assignUserOpen} className="d-flex justify-content-center">
            <Container className="p-1" style={{ overflowY: "scroll", overflowX: "hidden" }}>
              <div
                className="closeButtonContainer"
                onClick={() => {
                  setAssignUserOpen(false);
                  dispatch(resetWorkerList());
                }}
              >
                <CloseIcon />
              </div>
              <h5
                className="pt-3"
                style={{ textAlign: "center" }}
              >{`${orderNumber} - 加入員工`}</h5>
              <Row className="px-2 flex-center my-4 relative">
                <Col className="flex-center full-width relative">
                  <input
                    className="searchInput"
                    value={modalSearchText}
                    placeholder={"員工編號 / 中文姓名 / 手機號碼"}
                    onChange={(e) => setModalSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") fetchAllUsers();
                    }}
                  ></input>
                  <div className="mx-0" onClick={() => fetchAllUsers()}>
                    <SearchIcon />
                  </div>
                </Col>
              </Row>
              <Row className="my-3">
                <Col className="flex-center">
                  <Button color="primary" onClick={postAssignWorkersToOrder} className="w-80">
                    確認加入員工
                  </Button>
                </Col>
              </Row>
              <div className="flex-column-center full-width">
                <Nav
                  tabs
                  className="flex-row-around full-width"
                  style={{ height: "40px", flexWrap: "nowrap" }}
                >
                  {tabs.map((element) => {
                    const props = { tabContent: element, activeTab, toggle };
                    return <ReactstrapTabNavLink key={element} {...props} />;
                  })}
                </Nav>

                <TabContent activeTab={activeTab} className="flex-center full-width">
                  <form className="flex-center full-width">
                    {
                      {
                        SUPERVISOR: (
                          <AssignList
                            assignList={workerLists.supervisorList}
                            closeAction={setAssignUserOpen}
                            isAssignMode={true}
                            sortFunction={fetchAllUsers}
                          />
                        ),
                        FOREMEN: (
                          <AssignList
                            assignList={workerLists.foremenList}
                            closeAction={setAssignUserOpen}
                            isAssignMode={true}
                            sortFunction={fetchAllUsers}
                          />
                        ),
                        WORKER: (
                          <AssignList
                            assignList={workerLists.workerList}
                            closeAction={setAssignUserOpen}
                            isAssignMode={true}
                            sortFunction={fetchAllUsers}
                          />
                        ),
                      }[activeTab]
                    }
                  </form>
                </TabContent>
              </div>
            </Container>
          </Modal>
          <DeleteModal
            isOpen={modal}
            deleteTarget={editTarget}
            setModal={setModal}
            addItemString={"工程單員工"}
          />
        </>
      )}
    </Container>
  );
}

export default OrderByIdPage;
